/*=========================================================================================
 File Name: mutations.js
 Description: Vuex Store - mutations
 ----------------------------------------------------------------------------------------
 Item Name: Vuesax Admin - VueJS Dashboard Admin Template
 Author: Pixinvent
 Author URL: http://www.themeforest.net/user/pixinvent
 ==========================================================================================*/


const mutations = {

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  UPDATE_SIDEBAR_WIDTH(state, width) {
    state.sidebarWidth = width;
  },
  UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
    state.sidebarItemsMin = val;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
    state.isSidebarActive = value;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
  },
  UPDATE_WINDOW_BREAKPOINT(state, val) {
    state.breakpoint = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  SET_REFERRAL_USERS_TO_STORE(state, data) {
    state.fullReferralsUsers = data;
  }
};

export default mutations;
