/*=========================================================================================
 File Name: themeConfig.js
 Description: Theme configuration
 ----------------------------------------------------------------------------------------
 Item Name: Vuesax Admin - Clean Vue.js Dashboard Admin Template
 Author: Pixinvent
 Author URL: hhttp://www.themeforest.net/user/pixinvent
 ==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
let colors = {
  primary: "#39f",
  success: "#28C76F",
  danger : "#EA5455",
  warning: "#FF9F43",
  dark   : "#1E1E1E",
};

let screens = {
  "sm": "640px",
  // => @media (min-width: 640px) { ... }
  
  "md": "768px",
  // => @media (min-width: 768px) { ... }
  
  "lg": "1024px",
  // => @media (min-width: 1024px) { ... }
  
  "xl": "1920px",
  // => @media (min-width: 1280px) { ... }
};

import Vue    from "vue";
import Vuesax from "vuesax";


Vue.use(Vuesax, {
  theme: {
    colors,
    screens,
  },
});


// CONFIGS
const themeConfig = {
  theme            : "light",						        // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed : false,			    // options[Boolean]: true, false(default)
  navbarColor      : "#fff",				      // options[String]: HEX color / rgb / rgba / Valid HTML Color name - (default:
  // #fff)
  navbarType       : "floating",				    // options[String]: floating(default) / static / sticky / hidden
  footerType       : "static",				      // options[String]: static(default) / sticky / hidden
  routerTransition : "zoom-fade",		// options[String]: zoom-fade / slide-fade / fade-bottom / fade / zoom-out /
  // none(default)
  disableCustomizer: false,			    // options[Boolean]: true, false(default)
  hideScrollToTop  : false,				    // options[Boolean]: true, false(default)
  disableThemeTour : false,					// options[Boolean]: true, false(default)
  
  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
};

export default themeConfig;
