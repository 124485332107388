import i18n from "@/i18n/i18n";
import Vue  from "vue";


export default function(error) {
  Vue.prototype.$notification.error({
    message: i18n.t("error"),
    description : error,
  });
}
