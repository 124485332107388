/*=========================================================================================
 File Name: actions.js
 Description: Vuex Store - actions
 ----------------------------------------------------------------------------------------
 Item Name: Vuesax Admin - VueJS Dashboard Admin Template
 Author: Pixinvent
 Author URL: http://www.themeforest.net/user/pixinvent
 ==========================================================================================*/


import VuApi from "../includes/VuApi";
import axios from "axios";
/*=========================================================================================*/
import redirect404          from "@/assets/utils/redirect404";
import displayErrorDefault  from "@/assets/utils/displayErrorDefault";

const actions = {
  // eslint-disable-next-line
  getRuntimeConfig: ({commit}, payload) => {
    const options = {
      method         : "post",
      url            : VuApi.generateUrl("tg", "getruntimeconfig"),
      data           : {},
      withCredentials: true,
    };

    axios(options).then(res => {
      const i18n = res.data.i18n;

      for(let loc in i18n) {
        if(i18n.hasOwnProperty(loc)) {
          payload.$i18n.setLocaleMessage(loc, i18n[ loc ]);
        }
      }

    }).catch(err => {
      console.error(err);
    });
  },
  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  updateSidebarWidth({commit}, width) {
    commit("UPDATE_SIDEBAR_WIDTH", width);
  },
  toggleContentOverlay({commit}) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({commit}, val) {
    commit("UPDATE_THEME", val);
  },
  updateWindowWidth({commit}, width) {
    commit("UPDATE_WINDOW_WIDTH", width);
  },
  getChatReferralsList({commit}, chatId) {
    return axios
      .get(`${VuApi.generateApiUrl()}/cabinet/static/chatreferrals/${chatId}_34.json`)
      .then(({data}) => {
        commit("SET_REFERRAL_USERS_TO_STORE", data.users);
        return data;
      })
      .catch(err => {
        if (!err?.response) {
          displayErrorDefault('Network Error')
        } else {
          const status = err?.response?.status;

          if (status > 400 && status < 500) {
            redirect404();
          } else {
            const errMessage = err.response?.data?.message

            displayErrorDefault(errMessage ?? '')
          }
        }
      })
  },
  getChatActiveReferralsList({ commit }, chatId) {
    return axios
      .get(`${VuApi.generateApiUrl()}/cabinet/static/chatactivereferrals/${chatId}_34.json`)
      .then(({ data }) => {
        commit()
        return data
      })
      .catch(err => {
        if (!err?.response) {
          displayErrorDefault('Network Error')
        } else {
          const status = err?.response?.status;

          if (status > 400 && status < 500) {
            redirect404();
          } else {
            const errMessage = err.response?.data?.message

            displayErrorDefault(errMessage ?? '')
          }
        }
      })
  }
};

export default actions;
