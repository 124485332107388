export default class VuApi {

  static DOMAIN_URL     = `https://api.${process.env.VUE_APP_TARGET_PROJECT}.app`;
  static API_URL        = `https://api.${process.env.VUE_APP_TARGET_PROJECT}.app`;

  static generateApiUrl() {
    const newApiUrl = localStorage && localStorage.getItem("newApiUrl");

    if (newApiUrl) {
      return newApiUrl
    } else {
      const { hostname } = window.location

      const PROTOCOL = 'https://'
      const CLIENT_SUBDOMAIN = 'client.'
      const API_SUBDOMAIN = 'api.'

      if (hostname.startsWith(CLIENT_SUBDOMAIN)) {
        const newHost = hostname.replace(CLIENT_SUBDOMAIN, API_SUBDOMAIN)

        return PROTOCOL + newHost
      } else {
        return this.API_URL
      }
    }
  }

}
