<!-- =========================================================================================
	File Name: App.vue
	Description: Main vue file - APP
	----------------------------------------------------------------------------------------
	Item Name: Vuesax Admin - VueJS Dashboard Admin Template
	Author: Pixinvent
	Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import themeConfig from "@/../themeConfig.js";


  export default {
    watch  : {
      "$store.state.theme"(val) {
        this.toggleClassInBody(val);
      },
    },
    methods: {
      toggleClassInBody(className) {
        if(className == "dark") {
          if(document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark");
          document.body.classList.add("theme-dark");
        } else if(className == "semi-dark") {
          if(document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark");
          document.body.classList.add("theme-semi-dark");
        } else {
          if(document.body.className.match("theme-dark")) document.body.classList.remove("theme-dark");
          if(document.body.className.match("theme-semi-dark")) document.body.classList.remove("theme-semi-dark");
        }
      },
      handleWindowResize(event) {
        this.$store.dispatch("updateWindowWidth", event.currentTarget.innerWidth);
      },
    },
    mounted() {
      this.toggleClassInBody(themeConfig.theme);
      this.$nextTick(() => {
        window.addEventListener("resize", this.handleWindowResize);
      });
      this.$store.dispatch("updateWindowWidth", window.innerWidth);

      // Check i18n
      if(this.$i18n.messages.en === undefined) {
        this.$store.dispatch("getRuntimeConfig", this);
      }
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.handleWindowResize);
    },
  };

</script>
