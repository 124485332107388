/*=========================================================================================
 File Name: store.js
 Description: Vuex store
 ----------------------------------------------------------------------------------------
 Item Name: Vuesax Admin - VueJS Dashboard Admin Template
 Author: Pixinvent
 Author URL: http://www.themeforest.net/user/pixinvent
 ==========================================================================================*/


import Vue  from "vue";
import Vuex from "vuex";

import state     from "./state";
import getters   from "./getters";
import mutations from "./mutations";
import actions   from "./actions";


Vue.use(Vuex);


export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== "production",
});
