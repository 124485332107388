import Vue    from 'vue'
import Router from 'vue-router'

import routes from './includes/routes'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'


Vue.use(Router)

const router = new Router({
  mode  : "history",
  base  : process.env.DOMAIN_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes: routes,
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg")

  if(appLoading) {
    appLoading.style.display = "none"
  }
})

Sentry.init({
  Vue,
  dsn             : "https://d491dab2be0844a0a0e50b1ba9ad098b@client.chatkeeper.info/clientErrorHandler/3",
  integrations    : [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 20.0,
});

export default router
