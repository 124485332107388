/*=========================================================================================
 File Name: getters.js
 Description: Vuex Store - getters
 ----------------------------------------------------------------------------------------
 Item Name: Vuesax Admin - VueJS Dashboard Admin Template
 Author: Pixinvent
 Author URL: http://www.themeforest.net/user/pixinvent
 ==========================================================================================*/


const getters = {

  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),

  getAllUsers: state => {
    return state.fullReferralsUsers;
  },
  getUserById: state => id => {
    return state.fullReferralsUsers.find(user => user.user_id === id);
  }
};

export default getters;
