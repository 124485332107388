const messages = {
  ru: {
    'public_generated_at': "Дата генерации:",
    'public_referral_title': 'Реферал группы',
    'public_referrals_title': "Рефералы",
    'public_referrals_subtitle': "Подробная информация о рефералах",
    'public_reputation_subtitle': "Подробная информация о репутации",
    'rows_count': 'Количество строк',
    'error': "Ошибка",
    '404_error_text': "Страница не найдена"
  },
  en: {
    'public_generated_at': "Generation date:",
    'public_referral_title': 'Referral of group',
    'public_referrals_title': "Referrals",
    'public_referrals_subtitle': "Detailed information about referrals",
    'public_reputation_subtitle': "Detailed information about reputation",
    'rows_count': 'Rows count',
    'error': "Error",
    '404_error_text': "Page not found"
  }
};

export default messages;
