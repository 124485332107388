export default [
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path     : "",
    component: () => import("@/layouts/main/Main.vue"),
    children : [
      // =============================================================================
      // Theme Routes
      // =============================================================================
      {
        path     : "/",
        name     : "home",
        component: () => import("@/views/Home.vue"),
      },
    ],
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path     : "",
    component: () => import("@/layouts/full-page/FullPage.vue"),
    children : [
      // =============================================================================
      // PAGES
      // =============================================================================
      {
        path     : "chat/:id/reputation/",
        name     : "reputation",
        component: () => import("../../views/chat/reputation.vue"),
      },
      {
        path     : "chat/:id/referrals/",
        name     : "referrals",
        component: () => import("../../views/chat/Referrals.vue"),
      },
      {
        path     : "chat/:id/active-referrals/",
        name     : "active-referrals",
        component: () => import("../../views/chat/Referrals.vue"),
      },
      {
        path     : "chat/:id/referral/:referral_id",
        name     : "referral",
        component: () => import("../../views/chat/Referral.vue"),
      },
      {
        path     : "chat/:id/active-referral/:referral_id",
        name     : "referral",
        component: () => import("../../views/chat/Referral.vue"),
      },
      {
        path     : "chat/:id/full-referrals/",
        name     : "full-referrals",
        component: () => import("../../views/chat/FullReferrals.vue"),
      },
      {
        path     : "chat/:id/active-full-referrals/",
        name     : "active-full-referrals",
        component: () => import("../../views/chat/FullReferrals.vue"),
      },
      {
        path     : "chat/:id/full-referral/:referral_id",
        name     : "full-referrals-user",
        component: () => import("../../views/chat/FullReferral.vue"),
      },
      {
        path     : "404",
        name     : "404",
        component: () => import("@/views/pages/Error404.vue"),
      },
    ],
  },
  // Redirect to 404 page, if no match found
  {
    path    : "*",
    component: () => import("@/views/pages/Error404.vue"),
  },
];
